export const environment = {
  production: false,
  apiurl: 'https://staging.cymationinfotech.com/referrercrm/services',
    clientId: '5e0417a9a61fbebbf22efb5c2646bee1',
    username: 'centuryplysandbox@woohoo.in',
    password: 'WoohooUAT@1',
    clientSecret: '7d180a544d33745e18c10811ab975ee7'
  // apiurl: 'https://cmp.centuryply.com/referrercrm-uat/services',
  //   clientId: '8c05ff8998d27375752012c45c4d5ed8',
  //   username: 'shounak.chakraborty@centuryply.com',
  //   password: 'U+055;LdqzJ#',
  //   clientSecret: 'df2b81aff933239a278db8582600fc7a'
};
