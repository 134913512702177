import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HeaderComponent } from './header/header.component';
import { OtpformComponent } from './otpform/otpform.component';
import { IndexComponent } from './index/index.component';
import { SkilldevelopmentComponent } from './skilldevelopment/skilldevelopment.component';
import { WebinarsComponent } from './webinars/webinars.component';
import { VouchersComponent } from './vouchers/vouchers.component';
import { GiftvouchersComponent } from './giftvouchers/giftvouchers.component';
import { SpecialsComponent } from './specials/specials.component';
import { FooterComponent } from './footer/footer.component';

const routes: Routes = [
  { path: '', redirectTo: 'otp', pathMatch: 'full' },
  { path: 'otp', component: OtpformComponent },
  { path: 'otp/:mobile', component: OtpformComponent },
  { path: 'index', component: IndexComponent },
  // { path: 'category/:categoryId', component: CategoryComponent },
  { path: 'category/:categoryId', component: SkilldevelopmentComponent },
  { path: 'profile', component: WebinarsComponent },
  { path: 'vouchers/:categoryId', component: VouchersComponent },
  { path: 'giftvoucher', component: GiftvouchersComponent },
  { path: 'specials', component: SpecialsComponent },
  { path: '**', redirectTo: 'otp' } 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
