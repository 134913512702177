import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private productApiUrl = '/Mobileapi/microsite_member_voucher';
  private userproductApiUrl = '/Mobileapi/get_user_voucher_products';
  private createOrderApiUrl = '/Mobileapi/create_order';
  private createUserOrderApiUrl = '/Mobileapi/user_create_order';
  private tokenRefreshApiUrl = '/Mobileapi/giftvoucher_get_bearer_token';

  private bearerToken: string | null = null;
  private failedRequest: {
    request: HttpRequest<any>;
    observer: any;
  } | null = null;

  constructor(private http: HttpClient, private authService: AuthService) { }

  // private handleTokenRefresh(error: HttpErrorResponse, request: HttpRequest<any>, observer: any): Observable<any> {
  //   if (error.status === 401 && error.error.message.includes('oauth_problem=token_rejected')) {
  //     return this.refreshToken().pipe(
  //       switchMap(() => {
  //         if (this.failedRequest) {
  //           return this.retryFailedRequest(request, observer);
  //         } else {
  //           return of(null); 
  //         }
  //       }),
  //       catchError(err => {
  //         console.error('Token refresh error:', err);
  //         return throwError(err);
  //       })
  //     );
  //   }
  //   return throwError(error);
  // }

  // private refreshToken(): Observable<any> {
  //   const payloads = {
  //     "token": "609615431cd8d539b733876c7e0c511dSAGLcHZ6nxEBnE4XlJ1nmcPTZaOXOGIXW",
  //     "clientId": "5e0417a9a61fbebbf22efb5c2646bee1",
  //     "clientSecret": "7d180a544d33745e18c10811ab975ee7"
  //   };

  //   return this.http.post(this.tokenRefreshApiUrl, payloads).pipe(
  //     switchMap((response: any) => {
  //       const newBearerToken = response.bearerToken;
  //       this.authService.setBearerToken(newBearerToken);
  //       return new Observable<any>();
  //     }),
  //     catchError(err => {
  //       console.error('Token refresh error:', err);
  //       return throwError(err);
  //     })
  //   );
  // }

  // private retryFailedRequest(request: HttpRequest<any>, observer: any): Observable<any> {
  //   // Retry the original request with the new token
  //   const headers = new HttpHeaders({
  //     'Authorization': `Bearer ${this.authService.getBearerToken()}`
  //   });

  //   const updatedRequest = request.clone({ headers });

  //   return this.http.request(request.method, updatedRequest.url, {
  //     body: updatedRequest.body,
  //     headers: updatedRequest.headers,
  //     responseType: 'json'
  //   }).pipe(
  //     switchMap(response => {
  //       observer.next(response);
  //       observer.complete();
  //       return of(response);
  //     }),
  //     catchError(err => {
  //       observer.error(err);
  //       return throwError(err);
  //     })
  //   );
  // }

  fetchProducts(categoryId: string, member_type: string, user_code:any ,token: any): Observable<any> {
    const body = {
      voucher_category_id: categoryId,
      member_type: member_type,
      user_code: user_code,
      token: token
    };
    // console.log(body);
    // console.log(this.productApiUrl);
    return this.http.post<any>(this.productApiUrl, body);
  }

  getUserVoucherProducts(voucherCategoryId: string, userCode: string, token: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const body = {
      voucher_category_id: voucherCategoryId,
      user_code: userCode,
      token: token,
    };

    return this.http.post<any>(this.userproductApiUrl, body, { headers });
  }

  createUserOrder(orderData: FormData): Observable<any> {
    return this.http.post(this.createUserOrderApiUrl, orderData);
  }

  // createOrder(orderDetails: any): Observable<any> {
  //   return this.http.post<any>(this.createOrderApiUrl, orderDetails);
  // }

  createOrder(orderData: FormData): Observable<any> {
    return this.http.post(this.createOrderApiUrl, orderData);
  }

  setBearerToken(token: string): void {
    this.bearerToken = token;
  }

  getBearerToken(): string | null {
    return this.bearerToken;
  }
  

}
