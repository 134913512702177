import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductService } from '../product.service';
import { HttpClient, HttpRequest, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from '../auth.service';
import { AuthManagerService } from '../auth-manager.service';
import { ViewportScroller } from '@angular/common';
import { RemoveQuotesPipe } from '../remove-quotes.pipe';

@Component({
  selector: 'app-specials',
  templateUrl: './specials.component.html',
  styleUrls: ['./specials.component.css']
})
export class SpecialsComponent {

  categoryId: any;
    voucher_category_id: any;
    m_voucher_id: any;
    member_type: any;
    token: any = "609615431cd8d539b733876c7e0c511dSAGLcHZ6nxEBnE4XlJ1nmcPTZaOXOGIXW"
    products: any[] = [];
    profileData: any;
    bearerToken: any;
    selectedProduct: any;
    selectedPrice: number = 0;
    quantity: number = 1;
    remainingBalance: any;
    vouchersAvailable: number = 0;
    selectedIndex: any;
    categoryName: any;
  
    termsAndConditions: any; 
    termsLink: any;
    showTerms: boolean = false;
    expanded: boolean = false;
  
  
  
    constructor(
      private route: ActivatedRoute,
      private productService: ProductService,
      private http: HttpClient,
      private authService: AuthService,
      private authManager: AuthManagerService,
      private scroller: ViewportScroller
    ) { }
  
    ngOnInit(): void {
  
      this.profileData = localStorage.getItem('profileData');
      this.bearerToken = localStorage.getItem('bearerToken');
      this.route.queryParams.subscribe(params => {
        this.categoryId = +params['categoryId'];
        this.fetchProducts();
      });
      this.remainingBalance = this.selectedProduct?.remaining_voucher_amount || 0;
      this.resetLinkColors();
    }
  
    resetLinkColors() {
      const links = document.querySelectorAll('a');
      links.forEach(link => {
        link.style.color = 'blue';
        link.style.textDecoration = 'underline';
      });
    }
    
    fetchProducts(): void {
      const member_type = JSON.parse(this.profileData).category;
      const user_code = JSON.parse(this.profileData).user_code;
      this.productService.fetchProducts(this.categoryId, member_type, user_code, this.token)
        .subscribe(
          (response) => {
            console.log(response);
            if (response.response.data && response.response.data.length > 0) {
              this.products = response.response.data.map((product: { product_details: string }) => ({
                ...product,
                product_details: JSON.parse(product.product_details)
              }));
    
              this.voucher_category_id = this.products[0].voucher_category_id;
              this.categoryName = this.products[0]?.voucher_category_name;
  
              this.selectedProduct = this.products[0];
  
              // if (this.selectedProduct && this.selectedProduct.product_details) {
              //   const tnc = this.selectedProduct.product_details.tnc;
              //   if (tnc) {
              //     this.termsAndConditions = tnc.content || '';
              //     this.termsLink = tnc.link || '';
              //   } else {
              //     this.termsAndConditions = '';
              //     this.termsLink = '';
              //     console.warn('Terms and Conditions are not available for the selected product.');
              //   }
              // }
              
    
            } else {
              // alert('Voucher settings not found.Please contact support');
              alert('Offer Valid for Bronze Members and Above');
            }
          },
  
  
          (error) => {
            // console.error('API Error:', error);
          }
        );
  
    }
  
    openTermsLink(link: any): void {
      if (link) {
        window.open(link, '_blank');
      } else {
        alert('Terms and Conditions link is not available.');
      }
    }
  
    onProductSelect(product: any) {
      this.selectedProduct = {
        ...product,
        showTerms: false, 
        expanded: false,   
      };
    }
  
    // toggleTerms() {
    //   this.showTerms = !this.showTerms; //Togle k liye
    // }
  
    toggleTerms() {
      if (this.selectedProduct) {
        this.selectedProduct.showTerms = !this.selectedProduct.showTerms; //read more k liye
      }
    }
  
    toggleReadMore() {
      if (this.selectedProduct) {
        this.selectedProduct.expanded = !this.selectedProduct.expanded; // show more k liye
      }
    }
  
    // getDisplayedContent(): string {
    //   if (this.selectedProduct) {
    //     const content = this.selectedProduct.product_details.tnc.content;
    //     return this.selectedProduct.expanded ? content : content.slice(0, 200) + '...';
    //     return this.formatContent(this.selectedProduct.expanded ? content : content.slice(0, 200) + '...');
    //   }
    //   return '';
    // }
  
    getDisplayedContent(): string {
      if (this.selectedProduct) {
        const content = this.selectedProduct.product_details.tnc.content;
        // Get the displayed content based on whether it's expanded or not
        const displayedContent = this.selectedProduct.expanded ? content : content.slice(0, 200) + '...';
        // return this.formatContent(displayedContent);
        const formattedContent = this.formatContent(displayedContent);
        this.resetLinkColors();
      
        return formattedContent;
      }
      return '';
    }
  
    formatContent(content: string): string {
      const emailRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(?=\s|$|[^\w.-])/g;
      const urlRegex = /(https?:\/\/[^\s<]+)/g;
    
      content = content.replace(/<br\s*\/?>/g, '');
      content = content.replace(/<li>/g, '').replace(/<\/li>/g, '');
    
      content = content.replace(urlRegex, (url: string) => {
        const encodedUrl = encodeURI(url);
        return `<a href="${encodedUrl}" target="_blank" style="color: blue; text-decoration: underline;">${encodedUrl}</a>`;
      });
    
      content = content.replace(emailRegex, (email: string) => {
        const cleanedEmail = email.split(/[\s<>,;]+/)[0];
        return `<a href="mailto:${cleanedEmail}" style="color: blue; text-decoration: underline;">${cleanedEmail}</a>`;
      });
    
      return content;
    }
  
    // formatContent(content: any): string {
    //   const emailRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;
    //   const urlRegex = /(https?:\/\/[^\s]+)/g;
  
    //   return content
    //     .replace(urlRegex, '<a href="$&" target="_blank" style="color: blue; text-decoration: underline;">$&</a>')
    //     .replace(emailRegex, '<a href="mailto:$&" style="color: blue; text-decoration: underline;">$&</a>');
    // }
  
    // onCardClick(product: any): void {
    //   this.selectedProduct = this.selectedProduct === product ? null : { ...product, quantity: 1 };
  
    //   setTimeout(() => { this.scroller.scrollToAnchor("giftCardDetails") }, 100);
  
    // }
  
    // onCardClick(product: any): void {
    //   if (this.selectedProduct === product) {
    //     this.selectedProduct = null;
    //   } else {
    //     this.selectedProduct = { ...product, selectedPrice: product.selectedPrice || 0 };
    //     if (this.selectedProduct.selectedPrice === 0) {
    //       this.selectedProduct.selectedPrice = product.product_details.price.min;
    //     }
    //     this.updateRemainingBalance(product);
    //     this.quantity = 1;
    //   }
  
    //   setTimeout(() => { this.scroller.scrollToAnchor("giftCardDetails"); }, 100);
    // }
  
    onCardClick(product: any): void {
      if (this.selectedProduct === product) {
          this.selectedProduct = null;
      } else {
          this.selectedProduct = { ...product, selectedPrice: null };
          // if (product.selected_denominations && product.selected_denominations.length) {
          //     this.selectedProduct.selectedPrice = product.selected_denominations[0]; 
          // }
          this.updateRemainingBalance(product);
          this.quantity = 1;
      }
  
      setTimeout(() => { this.scroller.scrollToAnchor("giftCardDetails"); }, 100);
    }
  
    setIndex(index: number) {
      this.selectedIndex = index;
    }
  
    // onCheckboxChange(event: Event, product: any): void {
    //   const input = event.target as HTMLInputElement;
    //   const denomination = parseFloat(input.value);
  
    //   if (input.checked) {
    //     product.selectedPrice = denomination; // Set the selected denomination for the product
    //   }
  
    //   console.log('Selected denomination for product:', product.sku, product.selectedPrice);
    // }
  
  
    // onCheckboxChange(event: Event, product: any): void {
    //   const input = event.target as HTMLInputElement;
  
    //   product.selectedPrice = parseFloat(input.value);
  
    //   console.log('Selected denomination for product:', product.sku, product.selectedPrice);
    // }
  
    onCheckboxChange(event: Event, product: any): void {
      const input = event.target as HTMLInputElement;
      product.selectedPrice = parseFloat(input.value);
      console.log('Selected denomination for product:', product.sku, product.selectedPrice);
      this.updateRemainingBalance(product);
    }
  
  
    private updateRemainingBalance(product: any) {
      if (product.selectedPrice) {
          const totalSelectedPrice = product.selectedPrice * this.quantity;
          const newBalance = product.remaining_voucher_amount - totalSelectedPrice;
    
          if (newBalance >= 0) {
              this.remainingBalance = newBalance;
          } else {
              this.remainingBalance = 0;
              console.warn('Selected price exceeds remaining balance.');
              alert('You exceeded the remaining balance. Please try again later.');
          }
      } else {
          this.remainingBalance = product.remaining_voucher_amount;
      }
    
      this.vouchersAvailable = Math.floor(this.remainingBalance / 500);
      console.log('Remaining Balance:', this.remainingBalance);
      console.log('Vouchers Available:', this.vouchersAvailable);
    }
  
    updateQuantity(newQuantity: number): void {
      this.quantity = newQuantity; // Update the quantity
      if (this.selectedProduct) {
          this.updateRemainingBalance(this.selectedProduct); // Pass the selected product
      }
    }
  
    isOrderValid(product: any): boolean {
      const isWithinMinMax = product.selectedPrice >= product.product_details.price.min && product.selectedPrice <= product.product_details.price.max;
      return isWithinMinMax || (product.selected_denominations && product.selected_denominations.length > 0 && product.selectedPrice !== null);
    }
  
    onNumericInputChange(product: any): void {
      console.log('Selected amount for product:', product.sku, product.selectedPrice);
    }
  
  // async placeOrder(product: any): Promise<void> {
  //     if (!product) {
  //         alert('Please select a product before placing an order.');
  //         return;
  //     }
  
  //     const hasDenominations = product.selected_denominations && product.selected_denominations.length > 0;
  
  //     if (hasDenominations) {
  //         if (product.selectedPrice === undefined || product.selectedPrice === null) {
  //             alert('Please select a denomination before placing an order.');
  //             return;
  //         }
  //     } else {
  //         if (product.selectedPrice < product.product_details.price.min || product.selectedPrice > product.product_details.price.max) {
  //             alert('Selected amount is out of the allowed range.');
  //             return;
  //         }
  //     }
  
  //     // Confirm order placement only once
  //     const confirmOrder = confirm('Are you sure you want to place this order?');
  //     if (!confirmOrder) {
  //         return;
  //     }
  
  //     // Deduct amount only after confirmation
  //     const totalSelectedPrice = product.selectedPrice * this.quantity; // Calculate total price
  //     const newBalance = product.remaining_voucher_amount - totalSelectedPrice;
  
  //     if (newBalance < 0) {
  //         alert('Insufficient balance to place this order.');
  //         return;
  //     }
  
  //     // Proceed with placing the order and updating the balance
  //     await this.attemptOrderPlacement(product);
  //     this.remainingBalance = newBalance; // Update remaining balance after placing the order
  //     console.log('Remaining Balance after Order:', this.remainingBalance);
  // }
  
      async placeOrder(product: any): Promise<void> {
        if (!product) {
            alert('Please select a product before placing an order.');
            return;
        }
      
        const hasDenominations = product.selected_denominations && product.selected_denominations.length > 0;
      
        if (hasDenominations) {
            if (product.selectedPrice === undefined || product.selectedPrice === null) {
                alert('Please select a denomination before placing an order.');
                return;
            }
        } else {
            if (product.selectedPrice < product.product_details.price.min || product.selectedPrice > product.product_details.price.max) {
                alert('Selected amount is out of the allowed range.');
                return;
            }
        }
      
        // Confirm order placement only once
        const confirmOrder = confirm('Are you sure you want to place this order?');
        if (!confirmOrder) {
            return;
        }
      
        // Deduct amount only after confirmation
        const totalSelectedPrice = product.selectedPrice * this.quantity; // Calculate total price
        const newBalance = product.remaining_voucher_amount - totalSelectedPrice;
      
        if (newBalance < 0) {
            alert('Insufficient amount. You exceeded the remaining balance, Please try again later.');
            return;
        }
      
        // Proceed with placing the order and updating the balance
        await this.attemptOrderPlacement(product);
        this.remainingBalance = newBalance; // Update remaining balance after placing the order
        console.log('Remaining Balance after Order:', this.remainingBalance);
      }
  
    private async attemptOrderPlacement(product: any): Promise<void> {
      try {
          const architect_code = JSON.parse(this.profileData).user_code;
          const member_type = JSON.parse(this.profileData).category;
          const voucher_category_id = this.voucher_category_id;
  
          const formData: FormData = new FormData();
          formData.append('sku', product.sku || '');
          formData.append('price', product.selectedPrice.toString());
          formData.append('qty', this.quantity.toString());
          formData.append('giftMessage', product.product_details.description || '');
          formData.append('architect_code', architect_code);
          formData.append('bearerToken', this.bearerToken);
          formData.append('voucher_category_id', voucher_category_id);
          formData.append('member_type', member_type);
          formData.append('sync_only', this.quantity <= 4 ? 'true' : 'false');
  
          this.showLoadingIndicator(); 
  
          this.productService.createOrder(formData).subscribe(
            async (response) => {
                this.hideLoadingIndicator();
                console.log(response);
        
                const responseData = response.response || response;
        
                if (!responseData) {
                    alert('Unexpected Error. Please try again later.');
                    return;
                }
        
                const { status, msg, data } = responseData;
        
                if (status === 1) {
                    alert('Your JOYReward order has been placed successfully! You will receive the GIFT CARD CODE through the WhatsApp BOT and registered Email ID within 2 hours.');
                } else if (status === 'PROCESSING') {
                    const orderData = data.api_response.data;
                    alert(`Your order is in process. Order ID: ${orderData.orderId}. We will let you know when it's processed.`);
                } else if (status === 0) {
                  if (data && data.data && data.data.code === 401 && data.data.message === 'oauth_problem=token_rejected') {
                    await this.handleTokenRejection(product);
                } else {
                    switch (msg) {
                        case 'Regret, you just missed it.Stay tuned for more exiting rewards soon.':
                            alert('Regret, you just missed it.Stay tuned for more exiting rewards soon.');
                            break;
                        case 'Regret, you just missed it.Stay tuned for more exiting rewards soon.':
                            alert('Regret, you just missed it.Stay tuned for more exiting rewards soon.');
                            break;
                        case 'Order exceeds the allowed number of vouchers.':
                            alert('Order exceeds the allowed number of vouchers. Please contact support.');
                            break;
                        case 'There is some problem in the server.':
                            alert('There is a server issue. Please try again later.');
                            break;
                        case 'You do not have sufficient amount.':
                            alert('You do not have sufficient amount. Please try again.');
                            break;
                        case 'You exceeded the remaining balance. Please try again later.':
                            alert('You exceeded the remaining balance. Please try again later.');
                            break;
                        case 'Insufficient amount. You exceeded the remaining balance, Please try again later.':
                            alert('Insufficient amount. You exceeded the remaining balance. Please try again later.');
                            break;
                        case 'API Error':
                            alert('Request failed. Please check your credentials and try again.');
                            break;
                        default:
                            alert(msg || 'Failed to place the order. Please try again.');
                    }
                  }
                } else {
                    alert('Unexpected response from the server. Please try again later.');
                }
            },
            async (error) => {
                this.hideLoadingIndicator();
                console.error('Order placement error:', error);
                const errorMsg = this.getErrorMessage(error);
  
                if (errorMsg === 'oauth_problem=token_rejected') {
                  await this.handleTokenRejection(product);
              } else {
                  alert(`Error: ${errorMsg || 'An unexpected error occurred. Please try again.'}`);
              }
        
                // if (errorMsg === 'oauth_problem=token_rejected') {
                //     try {
                //         await this.authManager.updateBearerToken();
                //         this.bearerToken = localStorage.getItem('bearerToken');
                //         await this.attemptOrderPlacement(product);
                //     } catch (refreshError) {
                //         console.error('Failed to refresh token:', refreshError);
                //         alert('There is some issue with the server, please hold on for a moment and try again.');
                //         this.authManager.handleTokenRejected();
                //     }
                // } else {
                //     alert(`Error: ${errorMsg || 'An unexpected error occurred. Please try again.'}`);
                // }
            }
        );
        //   this.productService.createOrder(formData).subscribe(
        //     async (response) => {
        //         this.hideLoadingIndicator();
        
        //         // const responseData = response?.response?.data;
        //         const responseData = response.response || response;
        
        //         if (!responseData) {
        //             alert('Unexpected response format. Please try again later.');
        //             return;
        //         }
        
        //         const { status, msg, data } = responseData;
        
        //         if (status === 1) {
        //             const orderData = data.api_response.data;
        //             alert(`Order placed successfully! Your order is in processing. Order ID: ${orderData.orderId}`);
        //         } else if (status === 0) {
        //             switch (msg) {
        //                 case 'Voucher is not valid for the current date. Please check the validity period.':
        //                     alert('The voucher is not valid for the current date. Please check the validity period.');
        //                     break;
        //                 case 'API Error':
        //                     alert('API request failed. Please check your credentials and try again.');
        //                     break;
        //                 default:
        //                     alert(msg || 'Failed to place the order. Please try again.');
        //             }
        //         } else {
        //             alert('Unexpected response from the server. Please try again later.');
        //         }
        //     },
        //     async (error) => {
        //         this.hideLoadingIndicator();
        //         console.error('Order placement error:', error);
        //         const errorMsg = this.getErrorMessage(error);
        
        //         if (errorMsg === 'oauth_problem=token_rejected') {
        //             try {
        //                 await this.authManager.updateBearerToken();
        //                 this.bearerToken = localStorage.getItem('bearerToken');
        //                 await this.attemptOrderPlacement(product);
        //             } catch (refreshError) {
        //                 console.error('Failed to refresh token:', refreshError);
        //                 alert('There is some issue with the server, please hold on for a moment and try again.');
        //                 this.authManager.handleTokenRejected();
        //             }
        //         } else {
        //             alert(`Error: ${errorMsg || 'An unexpected error occurred. Please try again.'}`);
        //         }
        //     }
        // );
       
      } catch (error) {
          this.hideLoadingIndicator();  
          console.error('Error ensuring bearer token:', error);
          alert('An error occurred while processing your order. Please try again.');
      }
    }
  
    private async handleTokenRejection(product: any) {
      try {
          console.log('Attempting to update bearer token...');
          await this.authManager.updateBearerToken();
          this.bearerToken = localStorage.getItem('bearerToken');
          console.log('Updated bearer token:', this.bearerToken);
          // await this.attemptOrderPlacement(product);
          if (this.bearerToken) {
            await this.attemptOrderPlacement(product);
        } else {
            throw new Error('Bearer token is empty after refresh');
        }
      } catch (refreshError) {
          console.error('Failed to refresh token:', refreshError);
          alert('Your session is expired. You will be logged out now. Please login and try again.');
          this.authManager.handleTokenRejected();
      }
    }
  
    // private async attemptOrderPlacement(product: any): Promise<void> {
    //   try {
    //     // const bearerToken = await this.authManager.ensureBearerToken();
  
    //     const architect_code = JSON.parse(this.profileData).user_code;
    //     const member_type = JSON.parse(this.profileData).category;
    //     const voucher_category_id = this.voucher_category_id;
  
    //     const formData: FormData = new FormData();
    //     formData.append('sku', product.sku || '');
    //     formData.append('price', product.selectedPrice.toString());
    //     formData.append('qty', this.quantity.toString());
    //     formData.append('giftMessage', product.product_details.description || '');
    //     formData.append('architect_code', architect_code);
    //     formData.append('bearerToken', this.bearerToken);
    //     formData.append('voucher_category_id', voucher_category_id);
    //     formData.append('member_type', member_type);
    //     formData.append('sync_only', this.quantity <= 4 ? 'true' : 'false');
  
    //     this.showLoadingIndicator();  // Show loading indicator
  
    //     this.productService.createOrder(formData).subscribe(
    //       async (response) => {
    //         this.hideLoadingIndicator();  // Hide loading indicator
  
    //         const status = response?.response?.data?.data?.status;
  
    //         if (status === 'PROCESSING') {
    //           alert(`Your Order is in process. Order ID: ${response.response.data.data.orderId} we will let you know when it's processed`);
    //         } else if (response?.response?.status === 1) {
    //           // console.log('Order placed successfully:', response);
    //           alert('Order placed successfully!');
    //         } else {
    //           const errorMsg = this.getErrorMessage(response);
    //           // console.log('Failed to place the order:', response);
  
    //           if (errorMsg === 'oauth_problem=token_rejected') {
    //             try {
    //               // await this.authManager.fetchBearerToken();
    //               await this.authManager.updateBearerToken();
    //               this.bearerToken = localStorage.getItem('bearerToken');
    //               await this.attemptOrderPlacement(product);
    //             } catch (refreshError) {
    //               console.error('Failed to refresh token:', refreshError);
    //               alert('There is some issue with the server, please hold on for a moment and try again.');
    //               this.authManager.handleTokenRejected();
    //             }
    //           } else {
    //             // alert(`Failed to place the order: ${errorMsg}`);
    //             alert(`Failed to place the order.`);
    //           }
    //         }
    //       },
    //       async (error) => {
    //         this.hideLoadingIndicator();  // Hide loading indicator
  
    //         console.error('Order placement error:', error);
    //         const errorMsg = this.getErrorMessage(error);
    //         alert(`Error: ${errorMsg}`);
  
    //         if (error.error && error.error.message.includes('oauth_problem=token_rejected')) {
    //           alert('An error occurred while processing your order. Please login again.');
    //           this.authManager.handleTokenRejected();
    //         }
    //       }
    //     );
    //   } catch (error) {
    //     this.hideLoadingIndicator();  // Hide loading indicator
    //     console.error('Error ensuring bearer token:', error);
    //     alert('An error occurred while processing your order. Please try again.');
    //   }
    // }
  
  
    private showLoadingIndicator(): void {
      // Create a loading spinner or message
      const loadingIndicator = document.createElement('div');
      loadingIndicator.id = 'loadingIndicator';
      loadingIndicator.style.position = 'fixed';
      loadingIndicator.style.top = '0';
      loadingIndicator.style.left = '0';
      loadingIndicator.style.right = '0';
      loadingIndicator.style.bottom = '0';
      loadingIndicator.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
      loadingIndicator.style.color = 'white';
      loadingIndicator.style.display = 'flex';
      loadingIndicator.style.justifyContent = 'center';
      loadingIndicator.style.alignItems = 'center';
      loadingIndicator.style.zIndex = '9999';
      loadingIndicator.innerText = "Processing... Please don't refresh the page until the order is processed.";  // Loading text
      document.body.appendChild(loadingIndicator);
    }
  
    private hideLoadingIndicator(): void {
      const loadingIndicator = document.getElementById('loadingIndicator');
      if (loadingIndicator) {
        loadingIndicator.remove();  // Remove loading indicator from DOM
      }
    }
  
    private getErrorMessage(response: any): string {
      if (response?.response?.data?.data?.message) {
        return response.response.data.data.message;
      }
      if (response?.response?.msg) {
        return response.response.msg;
      }
      if (response?.error?.data?.message) {
        return response.error.data.message;
      }
      return 'An unexpected error occurred. Please try again later.';
    }
  
    blockManualInput(event: KeyboardEvent): void {
      const allowedKeys = ['ArrowUp', 'ArrowDown', 'Tab', 'Backspace', 'Delete'];
  
      if (!allowedKeys.includes(event.key)) {
          event.preventDefault();
      }
    }

}
